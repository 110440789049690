import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MaterialUiAppBar from '@material-ui/core/AppBar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useState } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { button } from './styles'
import { LoginButton } from '../loginButton/LoginButton'
import { useAuth0 } from '@auth0/auth0-react'

export const AppBar = () => {
  const { isAuthenticated, logout, user } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement | null | undefined>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    logout({ returnTo: window.location.origin })
    setAnchorEl(null)
  }

  return <MaterialUiAppBar position="static">
    <Toolbar>
      <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
      </IconButton>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      DISCGOLF.BET
      </Typography>
      { !isAuthenticated && <LoginButton/> }
      { isAuthenticated && <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          css={button}
          onClick={handleClick}
        >
          { user.name }
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
      </>}
    </Toolbar>
  </MaterialUiAppBar>
}
