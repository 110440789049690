import React from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import { button, datePicker, form, input, wrapper } from './styles'
import { postFetch } from '../../../utils/fetch'

export const CreateCompetition = () => {
  const [date, setDate] = React.useState<Date>(new Date())
  const [name, setName] = React.useState<string>('')
  const [location, setLocation] = React.useState<string>('')
  const isCreateCompetitionDisabled = !date || !name

  const updateDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(new Date(event.target.value))
  }

  const createCompetition = async () => {
    const response = await postFetch('/api/competition', JSON.stringify({
      date: date,
      location: location || undefined,
      name: name
    }))

    if (response.status === 200) {
      alert('Tournament added')
    } else if (response.status === 409) {
      alert('Tournament name already exists')
    } else {
      alert('Something went wrong')
    }
  }

  return (
    <div css={wrapper}>
      <Typography variant="h4">
        Create competition
      </Typography>
      <div css={form}>
        <TextField
          css={input}
          inputProps={{
            style: {
              color: '#000'
            }
          }}
          label="Name"
          onChange={(event) => setName(event.target.value)}
          variant="filled"
          value={name}
        />
        <TextField
          css={input}
          inputProps={{
            style: {
              color: '#000'
            }
          }}
          label="Location"
          onChange={(event) => setLocation(event.target.value)}
          variant="filled"
          value={location}
        />
        <form noValidate>
          <TextField
            css={datePicker}
            InputLabelProps={{
              shrink: true
            }}
            onChange={updateDate}
            type="date"
            value={date.toISOString().slice(0, 10)}
          />
        </form>
        <Button
          css={button}
          disabled={ isCreateCompetitionDisabled }
          onClick={createCompetition}
          variant="outlined"
        >
          Create competition
        </Button>
      </div>
    </div>
  )
}
