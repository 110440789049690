import { css } from '@emotion/react'

export const betSlip = css({
  background: 'rgb(22, 27, 34)',
  color: '#fff',
  fontSize: '16px'
})

export const betSlipWrapper = css({
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%'
})
