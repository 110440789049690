import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles'

export const Odds = () => {
  const navigate = useNavigate()
  const classes = useStyles()

  const lines = [
    {
      name: 'Ruth Schlissel',
      odds: 2.20
    }
  ]

  const [name, setName] = React.useState('')
  const [odds, setOdds] = React.useState(0)
  const [amount, setAmount] = React.useState(0)

  const selectPlay = (name: string, odds: number) => {
    setName(name)
    setOdds(odds)
  }

  const bet = () => {
    console.log('asd')
  }

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(event.target.value))
  }

  return (
    <div>
      <h1>
      Odds page
      </h1>
      <button onClick={ () => navigate('/home') }>
      Go to home page
      </button>
      <div className='Tournament'>
        <h2>
        Påskkastet 2020-04-06
        </h2>
        {
          lines.map(line => {
            return (
              <div className={ classes.row } key={ line.name }>
                <div>
                  { line.name }
                </div>
                <div className={ classes.odds } onClick={ () => selectPlay(line.name, line.odds) }>
                  { line.odds }
                </div>
              </div>
            )
          })
        }
      </div>
      <div>
        <h3>selection</h3>
        <p>person: {name}</p>
        <p>odds: {odds}</p>
        <label>
          Amount:
          <input type="number" value={amount} onChange={(event) => handleAmountChange(event)} />
        </label>
        <button onClick={() => bet()}>Bet!</button>
      </div>
    </div>
  )
}
