import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'

import { Admin } from './pages/admin/index'
import { Competition } from './pages/admin/Competition/Competition'
import { Main } from './pages/main/Main'
import { Odds } from './pages/odds/Odds'
import { Profile } from './pages/profile/Profile'

import { theme } from './theme/theme'
import { ThemeProvider } from '@material-ui/core/styles'

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>

          <Route path="/odds">
            <Odds />
          </Route>

          <Route path="/profile">
            <Profile/>
          </Route>

          <Route path="/admin">

            <Route path="/competition">
              <Competition/>
            </Route>

            <Route path="/">
              <Admin/>
            </Route>

          </Route>

          <Route path="/">
            <Main />
          </Route>

        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}
