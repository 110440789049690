import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    primary: {
      main: 'rgb(22, 27, 34)'
    },
    secondary: {
      main: 'rgb(244, 143, 177)'
    },
    text: {
      primary: 'rgba(240, 246, 252, 0.7)'
    }
  }
})
