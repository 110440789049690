import React, { useEffect, useState } from 'react'
import { getFetch } from '../../utils/fetch'
import { List, ListItem, Typography } from '@material-ui/core'

interface Players {
  id: string;
  name: string;
  pdgaNumber: number;
}

export const ListOfPlayers = () => {
  const [players, setPlayers] = useState<Players[]>([])
  useEffect(() => {
    (async () => {
      const response = await getFetch('/api/players')
      const body = await response.json()
      setPlayers(body.message)
    })()
  }, [])

  if (players === []) {
    return null
  }

  return <>
    <Typography variant="h3">
      List of players
    </Typography>
    <List>
      { players?.map((competition) => {
        return <ListItem key={ competition.name }>
          { competition.name }
        </ListItem>
      })}
    </List>
  </>
}
