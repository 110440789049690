import React from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import { button, form, input, wrapper } from './styles'
import { postFetch } from '../../../utils/fetch'

export const AddPlayer = () => {
  const [name, setName] = React.useState<string>('')
  const [pdgaNumber, setPdgaNumber] = React.useState<number | string>('')

  const createPlayer = async () => {
    const response = await postFetch('/api/add-player', JSON.stringify({
      name: name,
      pdgaNumber: pdgaNumber || undefined
    }))

    if (response.status === 200) {
      alert('Player added')
    } else if (response.status === 409) {
      alert('Name already exists')
    }
  }

  return (
    <div css={wrapper}>
      <Typography variant="h4">
        Add player
      </Typography>
      <div css={form}>
        <TextField
          css={input}
          inputProps={{
            style: {
              color: '#000'
            }
          }}
          label="Name of player"
          onChange={(event) => setName(event.target.value)}
          variant="filled"
          value={name}
        />
        <TextField
          css={input}
          inputProps={{
            style: {
              color: '#000'
            }
          }}
          label="PDGA number (optional)"
          onChange={(event) => setPdgaNumber(Number(event.target.value))}
          type="number"
          variant="filled"
          value={pdgaNumber}
        />
        <Button
          css={button}
          disabled={ !name }
          onClick={createPlayer}
          variant="outlined"
        >
        Add player
        </Button>
      </div>
    </div>
  )
}
