import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  odds: {
    '&:hover': {
      backgroundColor: 'red'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around'
  }
}))
