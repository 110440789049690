import { css } from '@emotion/react'

export const color = css({
  color: '#000'
})

export const currentPlayer = css({
  margin: '16px 0'
})

export const hideLastBorder = css({
  '&:last-child td, &:last-child th': {
    border: 0
  }
})

export const input = css({
  margin: '16px 0'
})

export const table = css({
  minWidth: '650px'
})

export const title = css({
  marginTop: '16px'
})

export const wrapper = css({
  display: 'flex',
  flexDirection: 'column',
  margin: '16px 0'
})

export const divisionSelector = css({
  margin: '16px 0'
})
