const BASE_URL = process.env.NODE_ENV === 'production'
  ? 'https://server-3oa4rfjaja-ew.a.run.app'
  : 'http://localhost:9000'

export const postFetch = async (url: string, content: string) => {
  const response = await fetch(BASE_URL + url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    body: content
  })
  return response
}

export const putFetch = async (url: string, content: string) => {
  const response = await fetch(BASE_URL + url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    body: content
  })
  return response
}

export const getFetch = async (url: string) => {
  const response = await fetch(BASE_URL + url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors'
  })
  return response
}
