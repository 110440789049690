import React from 'react'
import { BetSlip } from '../../components/BetSlip/BetSlip'
import { LandingPage } from '../LandingPage/LandingPage'

export const Main = () => {
  return <>
    <LandingPage/>
    <BetSlip/>
  </>
}
