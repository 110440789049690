import { betSlip, betSlipWrapper } from './styles'
import { Button, Card, CardContent, TextField, Typography } from '@material-ui/core'
import { useAuth0 } from '@auth0/auth0-react'

export const BetSlip = () => {
  const { isAuthenticated } = useAuth0()

  const confirmBet = () => {
    if (isAuthenticated) {
      console.log('Making Bet')
    } else {
      alert('Not logged in!')
    }
  }

  return (
    <div css={betSlipWrapper}>
      <Card
        css={betSlip}
        sx={{ minWidth: 275 }}
      >
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
      Bet Slip
          </Typography>
          <Typography variant="h5" component="div">
            Amatouren 2 - Lundbyparken
          </Typography>
          <Typography variant="body2">
            Ruth Schlissel - 2.80
          </Typography>
          <TextField id="outlined-basic" label="Stake" />
          <Button onClick={ confirmBet } variant="contained">Confirm Bet</Button>
        </CardContent>
      </Card>
    </div>
  )
}
