import { css } from '@emotion/react'

export const card = css({
  backgroundColor: 'rgb(22, 27, 34)'
})

export const header = css({
  textAlign: 'center'
})

export const line = css({
  display: 'flex',
  width: '100%'
})

export const lineName = css({
  width: '80%'
})

export const lineNumber = css({
  textAlign: 'center',
  width: '20%'
})
