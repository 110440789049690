import React from 'react'
import { AppBar } from '../../components/AppBar/AppBar'
import { Card, CardContent, Container, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { card, header, line, lineName, lineNumber } from './styles'
import { ListItemProps } from '@material-ui/core/ListItem'

function ListItemLink (props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />
}

export const LandingPage = () => {
  // const [competitions, setCompetitions] = useState<Competition[]>([])

  // useEffect(() => {
  // }, [])

  return <>
    <AppBar/>
    <h1 css={header}>
    Welcome to discgolf.bet
    </h1>

    <Container maxWidth="xl">
      <Card
        css={card}
      >
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            2021-05-09
          </Typography>
          <Typography variant="h5" component="div">
            Amatouren 2 - Lundbyparken
          </Typography>
          <div className="division">
            <hr></hr>
            <br></br>
            <Typography variant="h5" component="div">
            MA2
            </Typography>
            <Typography variant="body2">
              <List component="nav" aria-label="secondary mailbox folders">
                <ListItem button>
                  <div css={line}>
                    <span css={lineName}>
                      Ruth Schlissel
                    </span>
                    <span css={lineNumber}>
                      2.80
                    </span>
                  </div>
                </ListItem>
                <ListItemLink href="#simple-list">
                  <ListItemText primary="Axel Eriksson" />
                </ListItemLink>
              </List>
            </Typography>
          </div>
          <div className="division">
            <hr></hr>
            <br></br>
            <Typography variant="h5" component="div">
            FPO
            </Typography>
            <Typography variant="body2">
              <List component="nav" aria-label="secondary mailbox folders">
                <ListItem button>
                  <ListItemText primary="Ruth Schlissel" />
                </ListItem>
                <ListItemLink href="#simple-list">
                  <ListItemText primary="Linnea Ronnsten" />
                </ListItemLink>
              </List>
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Container>
  </>
}
