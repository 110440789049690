import React, { useEffect, useState } from 'react'
import { getFetch } from '../../utils/fetch'
import { List, ListItem, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

interface Competition {
  date: string;
  name: string;
  id: string;
}

export const ListOfCompetitions = () => {
  const [competitions, setCompetitons] = useState<Competition[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      const response = await getFetch('/api/competitions')
      const body = await response.json()
      setCompetitons(body)
    })()
  }, [])

  if (competitions === []) {
    return null
  }

  const goToCompetition = (id: string) => {
    navigate({
      pathname: '/admin/competition',
      search: `?id=${id}`
    })
    console.log(`Hello ${id}`)
  }

  return <>
    <Typography variant="h3">
      List of competitions
    </Typography>
    <Typography variant="h5">
      Click on one to see players for the competition
    </Typography>
    <List>
      { competitions?.map((competition) => {
        return <ListItem
          component="a"
          key={ competition.name }
          onClick={() => goToCompetition(competition.id)}
        >
          { competition.name }
        </ListItem>
      })}
    </List>
  </>
}
