import React, { useEffect, useState } from 'react'
import { Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import { color, currentPlayer, divisionSelector, hideLastBorder, input, table, title, wrapper } from './styles'
import { getFetch, postFetch, putFetch } from '../../../utils/fetch'
import { useNavigate } from 'react-router-dom'

interface Player {
  id: string;
  name: string;
  newOdds?: number;
  odds: number;
  division: string;
}

interface Division {
  id: string;
  name: string;
}

export const Competition = () => {
  const params = new URLSearchParams(window.location.search)
  const id = params.get('id')

  const navigator = useNavigate()
  const [name, setName] = useState<string>('')
  const [newPlayerName, setNewPlayerName] = useState<string>('')
  const [odds, setOdds] = useState<number | string>('')
  const [players, setPlayers] = useState<Player[]>([])

  const [divisions, setDivisions] = useState<Division[]>([])
  const [division, setDivision] = useState<Division>({
    name: '',
    id: ''
  })

  const returnToAdmin = () => {
    navigator('/admin')
  }

  useEffect(() => {
    (async () => {
      const response = await getFetch(`/api/competition/${id}`)
      const body = await response.json()
      setName(body.name)
    })()
  }, [id])

  useEffect(() => {
    (async () => {
      const response = await getFetch('/api/divisions')
      const body = await response.json()
      setDivisions(body as unknown as Division[])
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const response = await getFetch(`/api/competition/${id}/players`)
      const body = await response.json()
      setPlayers(body)
    })()
  }, [id])

  const updatePlayer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPlayerName(event.target.value)
  }

  const addPlayer = async () => {
    const response = await postFetch(`/api/competition/${id}/player`, JSON.stringify({
      division: division.id,
      playerName: newPlayerName,
      odds: odds
    }))
    if (response.ok) {
      alert('Player added')
    } else if (response.status === 400) {
      const body = await response.json()
      alert(body.message)
    } else {
      alert('Something went wrong')
    }
  }

  const newOdds = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
    const player = players.find(player => player.id === id)
    if (player) {
      player.newOdds = Number(event.target.value)
    }
  }

  const updateOdds = (playerId: string, odds?: number) => {
    if (odds) {
      putFetch(`/api/competition/${id}/player`, JSON.stringify({
        playerId: playerId,
        odds: odds
      }))
    }
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newDivision = divisions.find(division => {
      if (division.id === event.target.value) {
        return true
      } else {
        return false
      }
    })
    if (newDivision) {
      setDivision(newDivision)
    }
  }

  return (
    <Container maxWidth="xl">
      <br/>
      <Button onClick={returnToAdmin} variant="outlined">
        Return to Admin Panel
      </Button>
      <Typography css={title} variant="h3">
        {name}
      </Typography>
      <div css={wrapper}>
        <Typography variant="h4">
          Add Player to competition
        </Typography>
        <TextField
          css={input}
          inputProps={{
            style: {
              color: '#000'
            }
          }}
          label="Add player"
          onChange={updatePlayer}
          variant="filled"
          value={newPlayerName}
        />
        <TextField
          css={input}
          inputProps={{
            style: {
              color: '#000'
            }
          }}
          label="Odds"
          onChange={(event) => setOdds(event.target.value)}
          type="number"
          value={odds}
          variant="filled"
        />

        <div css={divisionSelector}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> Division</InputLabel>
            <Select
              css={ color }
              labelId="demo-simple-select-label"
              label="Division"
              onChange={handleChange}
              value={division.name}
              inputProps={{
                style: {
                  color: '#000',
                  opacity: 1
                }
              }}
              variant="filled"
            >
              {
                divisions.map(division => {
                  return (
                    <MenuItem css={color} key={division.id} value={division.id}>
                      {division.name}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
        </div>

        <Button onClick={ addPlayer } variant="outlined">
        Add Player
        </Button>
      </div>
      <Typography css={currentPlayer} variant="h4">
        { 'Current players'}
      </Typography>

      <TableContainer component={Paper}>
        <Table css={table}>
          <TableHead>
            <TableRow>
              <TableCell css={color}>Player</TableCell>
              <TableCell css={color}>Division</TableCell>
              <TableCell css={color}>Current Odds</TableCell>
              <TableCell css={color}>New Odds</TableCell>
              <TableCell css={color}>Submit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {players.map((player) => (
              <TableRow key={player.name} css={hideLastBorder}>
                <TableCell css={color} component="th" scope="row">
                  {player.name}
                </TableCell>
                <TableCell css={color} component="th" scope="row">
                  {player.division}
                </TableCell>
                <TableCell css={color} component="th" scope="row">
                  { player.odds }
                </TableCell>
                <TableCell css={color} component="th" scope="row">
                  <TextField
                    inputProps={{
                      style: {
                        color: '#000'
                      }
                    }}
                    onChange={(event) => newOdds(event, player.id)}
                    value={player.newOdds}
                  />
                </TableCell>
                <TableCell css={color} component="th" scope="row">
                  <Button color="primary" onClick={() => updateOdds(player.id, player.newOdds)} variant="outlined">
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}
