import { css } from '@emotion/react'

export const button = css({
  fontSize: '16px',
  marginTop: '16px'
})

export const datePicker = css({
  backgroundColor: 'rgb(22, 27, 34)',
  width: '250px'
})

export const form = css({
  display: 'flex',
  flexDirection: 'column'
})

export const input = css({
  color: '#000 !important',
  marginBottom: '16px'
})

export const wrapper = css({
  margin: '32px 16px'
})
