import React from 'react'
import { AddPlayer } from './AddPlayer/AddPlayer'
import { container } from './styles'
import { Container, Typography } from '@material-ui/core'
import { CreateCompetition } from './CreateCompetition/CreateCompetition'
import { ListOfCompetitions } from './ListOfCompetitions'
import { ListOfPlayers } from './ListOfPlayers'

export const Admin = () => {
  return (
    <Container
      css={container}
      maxWidth="xl"
    >

      <Typography variant="h2">
      Admin Panel
      </Typography>

      <CreateCompetition/>

      <AddPlayer/>

      <ListOfCompetitions/>

      <ListOfPlayers/>

    </Container>
  )
}
