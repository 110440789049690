// import { useAuth0 } from '@auth0/auth0-react'

export const Profile = () => {
  // const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  // const [userMetadata, setUserMetadata] = useState(null)

  return <>

  </>
}
